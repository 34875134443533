<template>
  <div class="page">
<!--    <van-nav-bar :title="'未取订单(' + list.length + ')'" fixed></van-nav-bar>-->
    <van-tabs type="card" @click="onClick" style="position: fixed;top: 0;width: 100%;z-index: 10">
      <van-tab title="未取订单"></van-tab>
      <van-tab title="超时订单"></van-tab>
      <van-tab title="全部订单" v-if="$store.getters.getProfile.permissions.includes('view_all_order')"></van-tab>
    </van-tabs>

    <van-dropdown-menu v-if="activeIndex === 2" style="position: relative;top: 40px" :close-on-click-overlay="false" :close-on-click-outside="false">
      <van-dropdown-item v-model="query.orderStatus" :options="orderStatusOptions" @change="onChangeQuery" />
      <van-dropdown-item title="筛选" ref="filter">
        <van-form>
          <van-field label="设备名称" placeholder="请输入" v-model="query.deviceName" clearable />
          <van-field label="订单号" placeholder="请输入" v-model="query.orderId" clearable />
          <van-field label="格子号" placeholder="请输入" v-model="query.cell" clearable />
          <van-field label="取餐码" placeholder="请输入" v-model="query.code" clearable />
          <van-field label="订单日期" v-model="dateRange" placeholder="请输入" readonly @click="showPickDateRange = true" clearable @clear="clearDateRange"/>
          <van-button round block type="danger" style="margin: 10px auto;width: 90%" @click="clickQuery">查询</van-button>
        </van-form>
      </van-dropdown-item>
    </van-dropdown-menu>

    <van-pull-refresh class="refresh" v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-collapse v-model="activeGroup" accordion v-if="!showAll">
          <van-collapse-item v-for="item in list" :key="item.title" :name="item.title">
            <template #title>
              <div>
                {{ item.title }}
                <van-tag style="margin-left: 10px" plain type="primary">{{ item.orders.length }}</van-tag>
              </div>
            </template>
            <van-cell v-for="order in item.orders" :key="order.orderId" :label="'存：' + order.keepTime" center is-link @click="clickCell(order)">
              <template #title>
                <div style="display: flex;align-orders: center">
                  <span style="margin-right: 10px">{{ order.deviceName }}</span>
                  <van-tag :type="order.type">{{ order.cellNo }}号</van-tag>
                  <van-tag v-if="order.collectCode" plain style="margin-left: 6px" type="primary" >{{ order.collectCode }}</van-tag>
                </div>
              </template>
              <template #label>
                <div v-if="order.orderStatus === '已取餐'">
                  <span>存：{{ order.keepTime }}</span><br>
                  <span>取：{{ order.collectTime }}</span>
                </div>
                <div v-else>
                  <span>存：{{ order.keepTime }}</span>
                  <span style="margin-left: 10px;color: #333333">{{ order.orderStatus }}</span>
                </div>
              </template>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
        <van-cell v-else v-for="order in list" :key="order.orderId" :label="'存：' + order.keepTime" center is-link @click="clickCell(order)">
          <template #title>
            <div style="display: flex;align-orders: center">
              <span style="margin-right: 10px">{{ order.deviceName }}</span>
              <van-tag :type="order.type">{{ order.cellNo }}号</van-tag>
              <van-tag v-if="order.collectCode" plain style="margin-left: 6px" type="primary" >{{ order.collectCode }}</van-tag>
            </div>
          </template>
          <template #label>
            <div v-if="order.orderStatus === '已取餐'">
              <span>存：{{ order.keepTime }}</span><br>
              <span>取：{{ order.collectTime }}</span>
            </div>
            <div v-else>
              <span>存：{{ order.keepTime }}</span>
              <span style="margin-left: 10px;color: #333333">{{ order.orderStatus }}</span>
            </div>
          </template>
        </van-cell>


        <!--        <van-cell v-for="item in list" :key="item.deviceId" :title="item.name" :label="'今日单量：' + item.todayOrderCount" />-->
      </van-list>
    </van-pull-refresh>

    <van-calendar v-model="showPickDateRange" type="range" @confirm="confirmDateRange" :min-date="minDate" :max-date="maxDate" :allow-same-day="true" />
    <van-action-sheet v-model="showActionSheet" :actions="sheetActions" @select="selectAction" cancel-text="取消" close-on-click-action close-on-click-overlay description="请选择操作" />
    <van-action-sheet v-model="showExpireSheet" :actions="expireSheetActions" @select="selectExpireAction" cancel-text="取消" close-on-click-action close-on-click-overlay description="请选择操作" />
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    const date = new Date()

    return {
      showAll: false,
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      showAdd: false,
      form: {deviceId: '', name: '', longitude: undefined, latitude: undefined},
      path: 'waitCollectOrder',
      activeIndex: 0,
      query: {deviceName: '', orderId: '', orderStatus: 0,startDate:'', endDate:'', pageNum: 1, cell: '', code: ''},
      orderStatusOptions: [
        {text:'订单状态',value:0},
        {text:'已取消',value:1},
        {text:'预订单',value:2},
        {text:'未取餐',value:3},
        {text:'已取餐',value:4},
      ],
      showPickDateRange: false,
      minDate: new Date(date.getFullYear() - 1, date.getMonth(), date.getDay()),
      maxDate: date,
      dateRange: '',
      activeGroup: '',
      showActionSheet: false,
      sheetActions: [
        {name: '获取更多信息', subname: '获取手机号及订单时间'},
        {name: '开门清理', subname: '取消订单并开门'},
        {name: '完成订单', subname: '完成订单并开门'},
      ],
      selOrder: undefined,
      showExpireSheet: false,
      expireSheetActions: [
        {name: '开门清理', subname: '开门并清洁'},
        {name: '续存订单', subname: '使用原有订单信息续存'},
      ],
      cancelType: {ADMIN: '管理员（清洁）', RIDER: '骑手', SYSTEM: '系统（下单超时）', OVERTIME: '超时'}
    }
  },
  methods: {
    confirmDateRange(date) {
      const [start, end] = date;
      this.showPickDateRange = false;
      // console.log(start, end)
      // console.log(start.toLocaleString());
      this.dateRange = start.toLocaleString().split(' ')[0] + ' - ' + end.toLocaleString().split(' ')[0]
      this.query.startDate = start.toLocaleString().split(' ')[0].replaceAll('/', '-')
      this.query.endDate = end.toLocaleString().split(' ')[0].replaceAll('/', '-')
      let startArr = this.query.startDate.split('-')
      startArr.forEach((val, index) => {
        if (val < 10) startArr[index] = `0${val}`
      })
      this.query.startDate = startArr.join('-')
      let endArr = this.query.endDate.split('-')
      endArr.forEach((val, index) => {
        if (val < 10) endArr[index] = `0${val}`
      })
      this.query.endDate = endArr.join('-')
    },
    clearDateRange() {
      this.query.startDate = ''
      this.query.endDate = ''
    },
    clickQuery() {
      this.list = []
      this.query.pageNum = 1
      this.$refs.filter.toggle()
      this.onLoad()
    },

    onClick(name) {
      this.activeIndex = name
      switch (name) {
        case 0:
          this.path = 'waitCollectOrder'
          this.showAll = false
          break
        case 1:
          this.path = 'overtimeOrder'
          this.showAll = false
          break
        case 2:
          this.path = 'allOrder'
          this.showAll = true
          break
      }
      this.onRefresh()
    },

    onRefresh() {
      this.list = []
      // this.query = {deviceName: '', orderId: '', orderStatus: 3,startDate:'', endDate:'', pageNum: 1}
      this.query.pageNum = 1
      this.onLoad()
    },
    onLoad() {
      if (this.activeIndex === 2) {
        this.queryAllOrder()
      } else {
        this.loading = true
        if (!this.refreshing) this.refreshing = true
        this.$http.get('data/' + this.path).then(res => {
          this.loading = false
          this.finished = true
          this.refreshing = false
          if (res.code === 200) {
            res.data.forEach(it => {
              let a = it.keepTime.split(/[^0-9]/)
              let keepTimestamp = new Date(a[0],a[1]-1,a[2],a[3],a[4],a[5]).getTime()
              if ((Date.now() - 6 * 3600000) >= keepTimestamp) {
                it.type = 'danger'
              } else if ((Date.now() - 4 * 3600000) >= keepTimestamp) {
                it.type = 'warning'
              } else {
                it.type = 'success'
              }
            })

            let groupMap = new Map()
            res.data.forEach(it => {
              if (groupMap.has(it.deviceName)) {
                groupMap.get(it.deviceName).push(it)
              } else {
                groupMap.set(it.deviceName, [it])
              }
            })
            let orderList = []
            groupMap.forEach((value, key) => {
              orderList.push({title: key, orders: value})
            })

            this.list = orderList
          } else {
            if (res.code === 403) {
              this.$toast.fail('登录信息过期，请重新登录')
              this.$bus.$emit('login')
            }
          }
        }).catch(() => {
          this.loading = false
          this.refreshing = false
          this.finished = true
        })
      }
    },

    onChangeQuery() {
      this.query.pageNum = 1
      this.list = []
      this.queryAllOrder()
    },

    queryAllOrder() {
      this.loading = true
      if (this.query.pageNum === 1 && !this.refreshing) this.refreshing = true
      this.$http.get('data/' + this.path, {params: this.query}).then(res => {
        this.loading = false
        this.refreshing = false
        if (res.code === 200) {
          this.list.push(...res.data)
          this.finished = res.data.length < 50
          this.query.pageNum++
        }
      }).catch(() => {
        this.loading = false
        this.refreshing = false
        this.finished = true
      })
    },

    clickCell(cabinet) {
      this.selOrder = cabinet
      switch (this.activeIndex) {
        case 0:
        case 2:
          this.showActionSheet = true
          break
        case 1:
          /*this.$dialog.confirm({message: '清理当前订单吗？'}).then(() => {
            // console.log('confirm')
            this.sendControl('cleanCell', cabinet)
          }).catch(() => {
            console.log('cancel')
          })*/
            this.showExpireSheet = true
          break
      }
    },

    async selectAction(action, index) {
      switch (index) {
        case 0: // 获取联系信息
          // eslint-disable-next-line no-case-declarations
          let res = await this.$http.get('cabinet/obtainContact', {params: {auvOrderId: this.selOrder.orderId}});
          if (res.code === 200) {
            this.$dialog.alert({
              title: '更多信息',
              message: `骑手：${res.data.riderPhone}\n\n用户：${res.data.userPhone ?? '--------------'}\n\n创建时间：${res.data.createTime ?? ''}\n\n取消时间：${res.data.cancelTime ?? ''}\n\n取消类型：${this.cancelType[res.data.cancelType] ?? ''}`
            }).then(() => {})
          }
          break
        case 1: // 清理订单
          this.sendControl('cleanCell', this.selOrder)
          break
        case 2: // 完成订单
          this.sendControl('finishOrder', this.selOrder)
          break
      }
    },

    async selectExpireAction(action, index) {
      switch (index) {
        case 0:
          // eslint-disable-next-line no-case-declarations
          let ret = await this.$http.post('cabinet/cleanExpireOrder', {auvOrderId: this.selOrder.orderId})
          if (ret.code === 200) {
            this.$toast('操作成功')
            this.onRefresh()
          }
          break
        case 1:
          // eslint-disable-next-line no-case-declarations
          let res = await this.$http.post('cabinet/keepStock', {auvOrderId: this.selOrder.orderId})
          if (res.code === 200) {
            this.$toast('操作成功')
            this.onRefresh()
          }
          break
      }
    },

    async sendControl(action, order) {
      this.$toast.loading({mask: true})
      let res = await this.$http.post('cabinet/' + action, {orderId: order.orderId, cabinetId: order.deviceId, eleSystem: true, cellNoList: [order.orderId], open: true, viceId: 0})
      this.$toast.clear()
      if (res.code === 200) {
        this.$toast.success({message:'操作成功'})
        setTimeout(this.onRefresh, 2000)
      } else if (res.code === 403) {
        this.$toast.fail({message:'权限不足'})
      } else {
        this.$toast.fail(res.msg)
      }
    },
  }
}
</script>

<style scoped>
/deep/ .van-list__finished-text {
  /*margin-bottom: 100px;*/
  padding-bottom: 100%;
  background-color: whitesmoke;
}
.refresh {
  margin-top: 46px;
  /*padding-bottom: 100px;*/
}
</style>
